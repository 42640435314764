import {
  Button,
  Container,
  Group,
  Title,
  Text,
  TextInput,
  Flex,
  Grid,
} from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
// import { UseFormReturnType } from "@mantine/form";

// import { FormValues } from "./BookingModal";

// interface Props {
//   form: UseFormReturnType<FormValues>;
//   nextStepText?: string;
//   onNext: () => unknown;
//   onPrevious: () => unknown;
// }

const ContactStep = ({
  form,
  nextStepText,
  onNext,
  onPrevious,
}) => {
  const isFormValid =
    form.isValid("contactStep.firstName") &&
    form.isValid("contactStep.lastName") &&
    form.isValid("contactStep.email") &&
    form.isValid("contactStep.phoneNumber");

  // Handle user attempting to progress to the next step
  function nextStep() {
    if (!form.isValid()) return;

    onNext();
  }

  return (
    <Container size="xl">
      <Title size="h1" mt="md" mb="sm" c="#3e4066">
        Last step!
      </Title>
      <Text mb="xl" c="#73769a">
        Provide us with your contact details
      </Text>
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            label="First Name"
            withAsterisk
            mb="xl"
            {...form.getInputProps("contactStep.firstName")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Last Name"
            withAsterisk
            mb="xl"
            {...form.getInputProps("contactStep.lastName")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Email"
            withAsterisk
            mb="xl"
            {...form.getInputProps("contactStep.email")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Phone Number"
            withAsterisk
            mb="xl"
            {...form.getInputProps("contactStep.phoneNumber")}
          />
        </Grid.Col>
      </Grid>
      <Group position="apart" mt="xl">
        <Button
          variant="subtle"
          color="dark"
          onClick={onPrevious}
          leftIcon={<IconArrowNarrowLeft />}
        >
          Back
        </Button>
        <Button
          type="submit"
          styles={(theme) => ({
            root: {
              color: "#3e4066",
              backgroundColor: "#00f85d",
              "&:hover": theme.fn.hover({
                backgroundColor: theme.fn.lighten("#00f85d", 0.3),
              }),
            },
          })}
          disabled={!isFormValid}
          onClick={(e) => {
            // Added this to prevent the click happening twice.
            e.preventDefault();
            nextStep();
          }}
        >
          {nextStepText}
        </Button>
      </Group>
    </Container>
  );
};

export default ContactStep;
