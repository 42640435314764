import styled from "@emotion/styled";
import {
  ActionIcon,
  Button,
  Container,
  Grid,
  Group,
  NumberInput,
  Title,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
// import { UseFormReturnType } from "@mantine/form";
import { IconCirclePlus, IconTrash } from "@tabler/icons-react";

import { TOMORROW_9AM, TOMORROW_5PM, getTimezoneAbbr } from "./utilities";
// import { FormValues } from "./BookingModal";

const NEW_SHIFT = {
  startsAt: TOMORROW_9AM,
  endsAt: TOMORROW_5PM,
};

// type GigRole = { label: string; value: string };

// interface Props {
//   form: UseFormReturnType<FormValues>;
//   gigRoles: GigRole[];
//   nextStepText?: string;
//   onNext: () => unknown;
// }

const RoleStep = ({
  form,
  gigRoles,
  nextStepText,
  onNext,
}) => {
  const isFormValid =
    form.isValid("roleStep.roleId") && form.isValid("roleStep.specialRequests");
  // const sortedRoles = [...gigRoles].sort((a, b) =>
  //   a.label.localeCompare(b.label)
  // );

  const datePickerValueFormat = (date) =>
    `MMM D, YYYY h:mm A [${getTimezoneAbbr(date)}]`;

  // Handle user attempting to progress to the next step
  function nextStep() {
    if (
      !form.isValid("roleStep.roleId") ||
      !form.isValid("roleStep.specialRequests")
    )
      return;
    onNext();
  }

  return (
    <Container size="xl">
      <Title size="h1" mt="md" mb="sm" c="#3e4066">
        Get started now
      </Title>
      <Text mb="lg" c="#73769a">
        Let us know the role and when
      </Text>
      <Select
        searchable
        label="Role"
        placeholder="Select a role"
        data={gigRoles}
        withAsterisk
        mb="sm"
        {...form.getInputProps("roleStep.roleId")}
      />
      <Textarea
        label="Special Requests"
        placeholder="Please add any special requests such as systems or equipment"
        minRows={4}
        maxRows={6}
        autosize
        mb="md"
        {...form.getInputProps("roleStep.specialRequests")}
      />
      <NumberInput
        required
        withAsterisk
        label="Daily Budget"
        description="Your daily budget for this role in USD."
        placeholder="Enter daily budget"
        precision={2}
        min={0}
        mb="md"
        {...form.getInputProps("roleStep.dailyBudget")}
      />
      {form.values.roleStep.shifts.map((_, index) => (
        <Grid mb="lg" key={index} sx={{ position: "relative" }}>
          <Grid.Col>
            <Group grow>
              <DateTimePicker
                required
                withAsterisk
                label="Start Date"
                minDate={new Date()}
                popoverProps={{ withinPortal: true }}
                mb="sm"
                valueFormat={
                  form.values.roleStep.shifts[index].startsAt
                    ? datePickerValueFormat(
                        form.values.roleStep.shifts[index].startsAt
                      )
                    : undefined
                }
                {...form.getInputProps(`roleStep.shifts.${index}.startsAt`)}
              />
            </Group>
            <Group grow>
              <DateTimePicker
                required
                withAsterisk
                label="End Date"
                minDate={new Date()}
                popoverProps={{ withinPortal: true }}
                mb="sm"
                valueFormat={
                  form.values.roleStep.shifts[index].endsAt
                    ? datePickerValueFormat(
                        form.values.roleStep.shifts[index].endsAt
                      )
                    : undefined
                }
                {...form.getInputProps(`roleStep.shifts.${index}.endsAt`)}
              />
            </Group>
          </Grid.Col>
          {form.values.roleStep.shifts.length > 1 && (
            <Tooltip label="Remove" withArrow position="bottom">
              <RemoveButton
                color="red"
                size="sm"
                onClick={() => form.removeListItem("roleStep.shifts", index)}
              >
                <IconTrash width="1rem" />
              </RemoveButton>
            </Tooltip>
          )}
        </Grid>
      ))}
      <Group position="apart">
        <Button
          size="xs"
          variant="subtle"
          color="dark"
          leftIcon={<IconCirclePlus width="1.2rem" />}
          onClick={() => form.insertListItem("roleStep.shifts", NEW_SHIFT)}
        >
          Additional Dates
        </Button>
      </Group>
      <Group position="right" mt="xl">
        <Button
          type="submit"
          styles={(theme) => ({
            root: {
              color: "#3e4066",
              backgroundColor: "#00f85d",
              "&:hover": theme.fn.hover({
                backgroundColor: theme.fn.lighten("#00f85d", 0.3),
              }),
            },
          })}
          disabled={!isFormValid}
          onClick={nextStep}
        >
          {nextStepText}
        </Button>
      </Group>
    </Container>
  );
};

// interface ActionButtonProps extends ActionIconProps {
//   onClick: () => void;
// }

const RemoveButton = styled(ActionIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export default RoleStep;
