import React from 'react';
import { Center, MantineProvider } from '@mantine/core';
import BookingModal from './BookingModal/BookingModal';
import axios from 'axios';
import moment from 'moment';
import { Loader } from '@mantine/core';


import {
  ROLE_STEP_NAME,
  GIG_STEP_NAME,
  CONTACT_STEP_NAME,
} from "./BookingModal/constants";

function Gagan() {

  const [isLoading, setIsLoading] = React.useState(false);
  const sendBookingData = (values) => {
    setIsLoading(true);
    axios
      .post(
        "https://api.retool.com/v1/workflows/93f23631-20b5-4b1f-901d-610db6ab6cd8/startTrigger?workflowApiKey=retool_wk_a8b359b7ba284d7da840b640c896ef07",
        {
          organizationId: "fe4ac591-9203-4b0a-b180-d14fadb9e61a", // Conneq Booking org
          customer: {
            email: values.contactStep.email,
            firstName: values.contactStep.firstName,
            lastName: values.contactStep.lastName,
            phoneNumber: values.contactStep.phoneNumber,
          },
          address: {
            street: values.gigStep.addressLine1,
            city: values.gigStep.city,
            state: values.gigStep.state,
            zip: values.gigStep.zipCode,
          },
          name: values.gigStep.gigName,
          description: `This gig was created through general booking.\n\n Name: ${values.contactStep.firstName} ${values.contactStep.lastName}\n Email: ${values.contactStep.email}\n Phone #: ${values.contactStep.phoneNumber}`,
          type: "PHYSICAL",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          location: `${values.gigStep.addressLine1} ${values.gigStep.addressLine2}, ${values.gigStep.city} ${values.gigStep.state} ${values.gigStep.zipCode}`,
          dailyRate: values.roleStep.dailyBudget * 100,
          jobs: {
            gigRoleId: values.roleStep.roleId,
            maxDailyRate: values.roleStep.dailyBudget * 100,
            shifts: values.roleStep.shifts.map((shift) => ({
              date: moment(shift.startsAt).format("YYYY-MM-DD"),
              endTime: moment(shift.endsAt).format("HH:mm"),
              inPerson: true,
              notes: values.roleStep.specialRequests,
              startTime: moment(shift.startsAt).format("HH:mm"),
            })),
          },
          dates: values.roleStep.shifts.map((shift) =>
            moment(shift.startsAt).format("YYYY-MM-DD")
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        // bookingModalHandler.close();
        window.location.href = `https://www.conneq.com/booking-request-submitted?gigId=${response.data.createGigData.createGig.gig.id}`;
        console.log("create rsvp response: ", response);
      })
      .catch(function (error) {
        window.alert("Error, the booking did not complete");
        console.error("error: ", error);
      });
  };

  if(isLoading) {
    return <Center><Loader/></Center>
  }
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
     <BookingModal
        requiredSteps={[ROLE_STEP_NAME, GIG_STEP_NAME, CONTACT_STEP_NAME]}
        onFinished={(values) => {
          console.log("Booking Form Values: ", values);
          sendBookingData(values);
        }}
      />
    </MantineProvider>
  );
}

export default Gagan;